<template>
  <div class="footer-container">
    <div class="footer-container-topImag"></div>
    <div class="footer-container-content">
      <div class="footer-container-content-cantact">
        公司电话：<a href="tel:0834-3301098">0830-8835888</a><br />
        客服热线：<a href="tel:400-1888-128">400-9119-888</a><br />
        邮 箱：<a href="mailto:yuanjd@lzlj.com" target="blank">yuanjd@lzlj.com</a><br />
        <div style="display: none">
          Q Q：<a href="tencent://message/?uin=2991217567&Site=&Menu-=yes">2991217567</a
          ><br />
        </div>
        地 址：泸州市纳溪区东升街道兴业路7号
      </div>
      <div class="footer-container-content-cantact-QRCode">
        <div class="footer-container-content-cantact-QRCode-img">
          <!-- <a href="../assets/footer/wx.jpg"> -->
          <!-- <link rel="icon" href="@/assets/Tiles.jpg" /> -->
          <img src="@/assets/footer/wx1.jpg" id="weixin" />
          <!-- </a> -->
        </div>
        <!-- <div class="footer-container-content-cantact-QRCode-img">
          <a href="http://sstwp.com:90/cms1/imgs/weibo.jpg">
            <img src="http://sstwp.com:90/cms1/imgs/weibologo.png" id="weibo" />
          </a>
        </div>
        <div class="footer-container-content-cantact-QRCode-img">
          <a href="http://sstwp.com:90/cms1/imgs/douyin.jpg">
            <img src="http://sstwp.com:90/cms1/imgs/douyinlogo.png" id="douyin" />
          </a>
        </div> -->
      </div>
    </div>
    <div class="footer-container-content-last">
      友情链接：
      <span style="margin-left: 10px"
        ><a href="https://www.lzlj.com/">泸州老窖集团</a></span
      >
      <span style="margin-left: 10px"
        ><a href="http://www.yuanjingda.com.cn">四川元景达食品有限公司</a></span
      >
    </div>
    <div class="footer-container-content-last">
      备案号:
      <span style="margin-left: 10px"
        ><a href="https://beian.miit.gov.cn/"> 蜀ICP备18008467号-1 </a></span
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
  cursor: auto;
}
.footer-container {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  background-color: #e4dbcc;
  margin-bottom: -15px;
}
.footer-container-topImag {
  width: 100%;
  height: 25px;
  background-image: url(../assets/footer/Tiles.jpg);
  background-repeat: repeat-x;
  background-size: 12px;
}
.footer-container-content {
  width: 80%;
  height: 70px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 15px;
  text-align: left;
  padding-top: 10px;
  margin-left: 10%;
  overflow: auto;
}
.footer-container-content-cantact {
}
.footer-container-content-cantact-QRCode-img {
  width: 50px;
  /* height: 50px; */
}
.footer-container-content-cantact-QRCode-img img {
  width: 100%;
}
.footer-container-content-cantact-QRCode {
  display: flex;
  justify-content: center;
  justify-items: center;
}
.footer-container-content-cantact-QRCode-img {
  margin-left: 20px;
}
.footer-container-content-last {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  /* margin-top: 10px; */
  padding-bottom: 10px;
}
</style>
